import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Peeling kawitacyjny | Gabinet Elżbieta</title>
    <link rel="canonical" href="https://gabinetelzbieta.pl/oferta/peeling-kawitacyjny/" />
  </>
);

const Main = styled.main`
  font-family: 'Lato', sans-serif;
`;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const MedycynaEstetyczna = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_4)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <h1>Peeling kawitacyjny</h1>

        <p className="lead">
          To nowoczesna metoda złuszczania naskórka, która polega na wykorzystaniu fali ultradźwiękowej do rozbijania sebum i&nbsp;martwych komórek skóry. Jest to bezinwazyjny zabieg, który nie wymaga użycia skalpela ani żadnych innych narzędzi chirurgicznych. Dzięki temu jest bezpieczny dla większości rodzajów skóry i&nbsp;może być wykonywany przez cały rok.
        </p>

        <p>Peeling kawitacyjny jest szczególnie polecany dla osób, które mają trądzik, przebarwienia skóry, blizny potrądzikowe, rozszerzone pory czy oznaki starzenia. Zabieg ten skutecznie usuwa martwe komórki skóry i&nbsp;zanieczyszczenia, co prowadzi do wygładzenia skóry i&nbsp;poprawy jej elastyczności. Peeling kawitacyjny jest również skutecznym sposobem na odmłodzenie skóry i&nbsp;zmniejszenie widoczności zmarszczek.</p>

        <p>Zabieg trwa zazwyczaj od 15 do 30 minut i&nbsp;jest bezbolesny. Po zabiegu skóra może być lekko zaczerwieniona, ale objawy te ustępują samoistnie po kilku godzinach. Efekty peelingu kawitacyjnego są widoczne już po pierwszym zabiegu, ale dla uzyskania trwałych rezultatów zaleca się wykonanie serii kilku zabiegów.</p>

        <p>Peeling kawitacyjny jest zabiegiem bezpiecznym dla większości rodzajów skóry, ale nie jest odpowiedni dla osób z&nbsp;chorobami skóry, takimi jak łuszczyca czy egzema. Przed wykonaniem zabiegu warto poradzić się specjalisty i&nbsp;upewnić się, że skóra jest w&nbsp;dobrym stanie i&nbsp;nadaje się do peelingu kawitacyjnego. Po zabiegu należy unikać ekspozycji na słońce i&nbsp;stosować ochronę przeciwsłoneczną oraz nawilżające kremy, aby zapobiec wysuszeniu skóry. Pielęgnacja skóry po zabiegu jest bardzo ważna, aby utrzymać uzyskane efekty i&nbsp;zapobiec podrażnieniom. Peeling kawitacyjny to skuteczna metoda, która pozwala cieszyć się zdrowym i&nbsp;młodym wyglądem skóry przez długi czas.</p>

        <h2>Cena: od 70 zł.</h2>

        <hr />

        <h2>Film prezentujący urządzenie Ultra Cavi Sonic Classic</h2>

        <iframe
          width="800"
          height="500"
          src="https://www.youtube.com/embed/bQqZLg7h7w8"
          title="Mikrodermabrazja diamentowa"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        >
        </iframe>
      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_4: file(relativePath: {regex: "/hero\/4.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    }
  }
`;

export default MedycynaEstetyczna;
